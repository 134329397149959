import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import logo from '../src/assets/JDLogo.png';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLanguage } from './contexts/LanguageContext';
import { translations } from './translations';
import Portfolio from './components/Portfolio';

function App() {
  const { language, toggleLanguage } = useLanguage();
  const t = translations[language];
  
  const [formStatus, setFormStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const recaptchaRef = useRef();

  const location = useLocation();
  const isPortfolioPage = location.pathname === '/portfolio';

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-fade-in');
          entry.target.classList.add('opacity-100');
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    document.querySelectorAll('.animate-on-scroll').forEach((element) => {
      element.classList.add('opacity-0');
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  const scrollToContact = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById('contact');
    contactSection.scrollIntoView({ behavior: 'smooth' });
  };

  // If we're on the portfolio page, render the Portfolio component
  if (isPortfolioPage) {
    return <Portfolio />;
  }

  // Otherwise render the main landing page
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="bg-white shadow-md fixed w-full z-50 transition-all duration-300">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <img src={logo} alt="Joshua Digital" className="h-10 w-10 hover:scale-105 transition-transform duration-300 rounded-full object-cover" />
            </div>
            {/* Desktop Menu */}
            <div className="hidden md:flex items-center space-x-8">
              <a href="#process" className="text-dark hover:text-primary transition-colors duration-300">{t.nav.process}</a>
              <a href="#faq" className="text-dark hover:text-primary transition-colors duration-300">{t.nav.faq}</a>
              <button 
                onClick={scrollToContact}
                className="text-white bg-primary px-6 py-2 rounded-lg hover:bg-opacity-90 transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1"
              >
                {t.nav.contact}
              </button>
              <button
                onClick={toggleLanguage}
                className="text-primary hover:text-opacity-80 transition-colors duration-300 font-medium"
              >
                {language === 'nl' ? 'EN' : 'NL'}
              </button>
            </div>
            {/* Mobile Menu Button */}
            <div className="md:hidden flex items-center">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="text-gray-600 hover:text-primary transition-colors duration-300"
                aria-label="Toggle menu"
              >
                {!isMobileMenuOpen ? (
                  <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                  </svg>
                ) : (
                  <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        <div className={`md:hidden transition-all duration-300 ease-in-out ${isMobileMenuOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0 pointer-events-none'} overflow-hidden bg-white border-t border-gray-100`}>
          <div className="px-4 pt-2 pb-3 space-y-1">
            <a
              href="#process"
              className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-primary transition-colors duration-300"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {t.nav.process}
            </a>
            <a
              href="#faq"
              className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-primary transition-colors duration-300"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {t.nav.faq}
            </a>
            <button
              onClick={() => {
                scrollToContact();
                setIsMobileMenuOpen(false);
              }}
              className="block w-full text-left px-3 py-2 text-base font-medium text-primary hover:text-primary-dark transition-colors duration-300"
            >
              {t.nav.contact}
            </button>
            <button
              onClick={() => {
                toggleLanguage();
                setIsMobileMenuOpen(false);
              }}
              className="block w-full text-left px-3 py-2 text-base font-medium text-primary hover:text-primary-dark transition-colors duration-300"
            >
              {language === 'nl' ? 'Switch to English' : 'Schakel naar Nederlands'}
            </button>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="bg-gradient-to-r from-gradient-start to-gradient-end pt-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center animate-on-scroll">
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-6 leading-tight">
              {t.hero.title}
            </h1>
            <p className="text-xl text-white/90 mb-8 max-w-3xl mx-auto">
              {t.hero.subtitle}
            </p>
            <ul className="text-white/90 mb-12 max-w-3xl mx-auto space-y-4">
              {t.hero.bulletPoints.map((point, index) => (
                <li key={index} className="flex items-center justify-center text-lg">
                  <span className="w-2 h-2 bg-white rounded-full mr-3"></span>
                  {point}
                </li>
              ))}
            </ul>
            <button 
              onClick={scrollToContact}
              className="bg-white text-primary px-8 py-4 rounded-lg font-semibold hover:bg-gray-100 transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1 group"
            >
              {t.hero.cta}
              <span className="inline-block transition-transform duration-300 group-hover:translate-x-1 ml-2">→</span>
            </button>
            <p className="text-sm text-white/80 mt-4">{t.hero.quickResponse}</p>
          </div>
        </div>
      </div>

      {/* USP Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-4 text-black">{t.usp.title}</h2>
          <p className="text-xl text-center mb-12 text-gray-600">{t.usp.subtitle}</p>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-8 mb-16">
            {t.usp.items.map((item, index) => (
              <div 
                key={index} 
                className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 animate-on-scroll text-center"
              >
                <div 
                  className="mb-4 flex justify-center transition-transform duration-300 hover:scale-110"
                  dangerouslySetInnerHTML={{ __html: item.icon.replace('className', 'class') }}
                />
                <h3 className="text-xl font-bold mb-2 text-black">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>

          <div className="mt-20">
            <h3 className="text-2xl font-bold text-center mb-4 text-black">{t.usp.examples.title}</h3>
            <p className="text-lg text-center mb-12 text-gray-600">{t.usp.examples.subtitle}</p>
            
            <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-8">
              {t.usp.examples.items.map((example, index) => (
                <div 
                  key={index}
                  className="bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition-all duration-300"
                >
                  <p className="text-gray-700">{example}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="text-center mt-12">
            <button 
              onClick={scrollToContact}
              className="inline-flex items-center text-primary hover:text-primary-dark transition-colors duration-300 font-semibold group"
            >
              {t.usp.cta}
              <span className="inline-block transition-transform duration-300 group-hover:translate-x-1 ml-2">→</span>
            </button>
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section id="process" className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12 text-black">{t.process.title}</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {t.process.steps.map((phase, index) => (
              <div key={index} className="group relative bg-white p-8 rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl transform hover:-translate-y-2">
                <div className="text-primary font-bold text-5xl mb-4 transition-all duration-300 group-hover:scale-110 opacity-80">{phase.step}</div>
                <h3 className="text-xl font-bold mb-3 text-black">{phase.title}</h3>
                <p className="text-gray-600">{phase.description}</p>
                {index < t.process.steps.length - 1 && (
                  <div className="hidden lg:block absolute -right-12 top-1/2 transform -translate-y-1/2 text-4xl text-primary opacity-50">→</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Case Study Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12 text-black">{t.caseStudy.title}</h2>
          
          <div className="space-y-12">
            {t.caseStudy.cases.map((caseItem, index) => (
              <div key={index} className="grid md:grid-cols-2 gap-12 items-center bg-gray-50 rounded-lg p-8">
                <div className="space-y-6">
                  <h3 className="text-2xl font-bold text-black">{caseItem.title}</h3>
                  <p className="text-gray-600">{caseItem.description}</p>
                  
                  <div className="space-y-4">
                    <h4 className="text-lg font-semibold text-black">Resultaten:</h4>
                    <ul className="space-y-2">
                      {caseItem.results.map((result, idx) => (
                        <li key={idx} className="flex items-center text-gray-600">
                          <svg className="w-5 h-5 mr-2 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>
                          {result}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="bg-gradient-to-br from-gradient-start to-gradient-end p-8 rounded-lg text-white space-y-6">
                  <button 
                    onClick={scrollToContact}
                    className="mt-6 w-full bg-white text-primary px-6 py-3 rounded-lg font-semibold hover:bg-opacity-90 transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1 group"
                  >
                    {t.caseStudy.cta}
                    <span className="inline-block transition-transform duration-300 group-hover:translate-x-1 ml-2">→</span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12 text-black">{t.faq.title}</h2>
          <div className="max-w-3xl mx-auto space-y-6">
            {t.faq.questions.map((faq, index) => (
              <div key={index} className="bg-gray-50 rounded-lg p-6 shadow-lg transition-all duration-300 hover:shadow-xl">
                <h3 className="text-lg font-bold mb-3 text-black">{faq.question}</h3>
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Form */}
      <section id="contact" className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-4 text-black">{t.contact.title}</h2>
          <p className="text-center text-gray-600 mb-12 max-w-3xl mx-auto">
            {t.contact.subtitle}
          </p>
          <div className="max-w-3xl mx-auto">
            <div className="bg-white rounded-lg shadow-xl p-8 animate-on-scroll relative text-center">
              <a 
                href="mailto:info@joshua-digital.nl"
                className="inline-flex items-center bg-primary text-white px-8 py-4 rounded-lg font-semibold hover:bg-opacity-90 transition-all duration-300 transform hover:-translate-y-1 group"
              >
                {t.contact.form.sendEmail || "Stuur ons een e-mail"}
                <span className="inline-block transition-transform duration-300 group-hover:translate-x-1 ml-2">→</span>
              </a>
              <p className="text-gray-600 mt-6">
                Of mail direct naar: info@joshua-digital.nl
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-gradient-start to-gradient-end text-white py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div className="space-y-2">
              <div>
                <h3 className="font-bold mb-1">Email</h3>
                <a 
                  href="mailto:info@joshua-digital.com" 
                  className="text-white/80 hover:text-white transition-colors duration-300"
                >
                  info@joshua-digital.com
                </a>
              </div>
              <div>
                <h3 className="font-bold mb-1">LinkedIn</h3>
                <a 
                  href="https://nl.linkedin.com/company/joshua-digital?trk=public_profile_topcard-current-company"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white/80 hover:text-white transition-colors duration-300"
                >
                  Joshua Digital
                </a>
              </div>
            </div>
            <div className="flex justify-end">
              <img 
                src={logo} 
                alt="Joshua Digital Logo" 
                className="h-24 w-24 object-contain drop-shadow-2xl"
              />
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-white/20 text-center text-white/80">
            <p>&copy; {new Date().getFullYear()} Joshua Digital. {t.footer.rights}</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

// Wrap the App component with Router
const AppWithRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/portfolio" element={<Portfolio />} />
      </Routes>
    </Router>
  );
};

export default AppWithRouter;
