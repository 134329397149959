import React, { useState } from 'react';
import mindfuel1 from '../assets/portfolio-img/mindfuel1.webp';
import mindfuel2 from '../assets/portfolio-img/mindfuel2.webp';
import mindfuel3 from '../assets/portfolio-img/mindfuel3.webp';
import prodify1 from '../assets/portfolio-img/prodify_listing1.png';
import prodify2 from '../assets/portfolio-img/prodify_listing2.png';
import prodify3 from '../assets/portfolio-img/prodify_listing3.png';
import nihaya1 from '../assets/portfolio-img/nihaya1.png';
import nihaya2 from '../assets/portfolio-img/nihaya2.png';
import nihaya3 from '../assets/portfolio-img/nihaya3.png';
import logo from '../assets/JDLetters.png';

const Portfolio = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const mindfuelImages = [
    { src: mindfuel1, alt: 'Mindfuel App Screenshot 1' },
    { src: mindfuel2, alt: 'Mindfuel App Screenshot 2' },
    { src: mindfuel3, alt: 'Mindfuel App Screenshot 3' }
  ];

  const prodifyImages = [
    { src: prodify1, alt: 'Prodify App Screenshot 1' },
    { src: prodify2, alt: 'Prodify App Screenshot 2' },
    { src: prodify3, alt: 'Prodify App Screenshot 3' }
  ];

  const nihayaImages = [
    { src: nihaya1, alt: 'Nihaya Website Screenshot 1' },
    { src: nihaya2, alt: 'Nihaya Website Screenshot 2' },
    { src: nihaya3, alt: 'Nihaya Website Screenshot 3' }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Modal for full-size image */}
      {selectedImage && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4"
          onClick={() => setSelectedImage(null)}
        >
          <div className="relative max-w-4xl w-full">
            <button 
              className="absolute top-4 right-4 text-white text-xl hover:text-gray-300"
              onClick={() => setSelectedImage(null)}
            >
              ✕
            </button>
            <img 
              src={selectedImage} 
              alt="Full size" 
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>
      )}

      {/* Header */}
      <header className="bg-gradient-to-r from-gradient-start to-gradient-end text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">Portfolio van Joshua Digital</h1>
          <p className="text-xl text-white/90 max-w-3xl">
            Bekijk een selectie van mijn projecten en werkervaring. Elk project weerspiegelt mijn kernwaarden: persoonlijke aandacht, kwaliteit, klanttevredenheid, en flexibiliteit.
          </p>
        </div>
      </header>

      {/* Projects */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-8">
            {/* WADM Mindfuel App */}
            <div className="bg-white rounded-lg shadow-xl p-8">
              <div className="flex justify-between items-start mb-4">
                <h2 className="text-3xl font-bold text-gray-900">WADM Mindfuel App</h2>
                <a 
                  href="https://www.linkedin.com/posts/joshua-silva-maniche_stageopdracht-bij-wadm-de-mindfuel-event-activity-7258818837872095233-0dGX?utm_source=share&utm_medium=member_desktop" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="inline-flex items-center gap-2 text-primary hover:text-primary-dark transition-colors duration-300"
                >
                  <span>Bekijk op LinkedIn</span>
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                  </svg>
                </a>
              </div>
              
              <p className="text-gray-600 mb-6">
                Een app ontwikkeld voor het Mindfuel-evenement van WADM, gericht op neuro-based branding.
              </p>
              
              {/* Image Gallery */}
              <div className="mb-8">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">App Screenshots</h3>
                <div className="grid grid-cols-3 gap-4">
                  {mindfuelImages.map((image, index) => (
                    <div 
                      key={index}
                      className="relative group cursor-pointer overflow-hidden rounded-lg"
                      onClick={() => setSelectedImage(image.src)}
                    >
                      <img 
                        src={image.src} 
                        alt={image.alt}
                        className="w-full h-auto transform transition-transform duration-300 group-hover:scale-105"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity duration-300 flex items-center justify-center">
                        <span className="text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          Bekijk
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Technische Stack</h3>
                <p className="text-gray-600">
                  Ontwikkeld met React Native en Expo voor cross-platform compatibiliteit, geïntegreerd met Firebase voor real-time dataverwerking en gebruikersauthenticatie.
                </p>
              </div>
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Kernfunctionaliteiten</h3>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                  <li>Geavanceerd QR-systeem voor efficiënte event check-ins</li>
                  <li>Real-time analytics dashboard voor bezoekersstromen</li>
                  <li>Progressive Web App voor optimale toegankelijkheid</li>
                  <li>Real-time synchronisatie tussen alle event-medewerkers</li>
                </ul>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Impact & Resultaten</h3>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                  <li>Succesvolle implementatie tijdens het Mindfuel-evenement</li>
                  <li>Significante verbetering in check-in efficiëntie</li>
                  <li>Positieve gebruikersfeedback op UX/UI design</li>
                  <li>Verhoogde engagement door real-time interacties</li>
                </ul>
              </div>
            </div>

            {/* Prodify */}
            <div className="bg-white rounded-lg shadow-xl p-8">
              <div className="flex justify-between items-start mb-4">
                <h2 className="text-3xl font-bold text-gray-900">Prodify</h2>
                <a 
                  href="https://prodify-app.com" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="inline-flex items-center gap-2 text-primary hover:text-primary-dark transition-colors duration-300"
                >
                  <span>Bezoek Website</span>
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </a>
              </div>

              <p className="text-gray-600 mb-6">
                Een app ontwikkeld voor de TikTokShop Guild, waarvoor ik ben uitgekozen als officiële TikTokShop-partner.
              </p>

              {/* Image Gallery */}
              <div className="mb-8">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">App Screenshots</h3>
                <div className="grid grid-cols-3 gap-4">
                  {prodifyImages.map((image, index) => (
                    <div 
                      key={index}
                      className="relative group cursor-pointer overflow-hidden rounded-lg"
                      onClick={() => setSelectedImage(image.src)}
                    >
                      <img 
                        src={image.src} 
                        alt={image.alt}
                        className="w-full h-auto transform transition-transform duration-300 group-hover:scale-105"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity duration-300 flex items-center justify-center">
                        <span className="text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          Bekijk
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Projectdoelstelling</h3>
                <p className="text-gray-600">
                  Ontwikkeling van een geavanceerd AI-gedreven platform dat TikTok Shop-gebruikers in staat stelt producten efficiënt te identificeren en te optimaliseren voor maximale verkoopprestaties.
                </p>
              </div>
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Technische Infrastructuur</h3>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                  <li>OpenAI API voor geavanceerde beeldherkenning en analyse</li>
                  <li>Custom-built algoritmes voor productmatching</li>
                  <li>Schaalbare cloud-infrastructuur voor real-time verwerking</li>
                  <li>Geoptimaliseerde caching voor snelle responstijden</li>
                </ul>
              </div>
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Kernfunctionaliteiten</h3>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                  <li>AI-gestuurde productherkenning via afbeeldingsanalyse</li>
                  <li>Geavanceerd metadata-matching systeem</li>
                  <li>Geautomatiseerde productoptimalisatie suggesties</li>
                  <li>Real-time marktanalyse en trending products</li>
                </ul>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Impact & Resultaten</h3>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                  <li>Officiële TikTok Shop-partnerschap status behaald</li>
                  <li>Significante verbetering in product-matching accuraatheid</li>
                  <li>Verhoogde efficiëntie voor e-commerce ondernemers</li>
                </ul>
              </div>
            </div>

            {/* Nihaya Website */}
            <div className="bg-white rounded-lg shadow-xl p-8">
              <div className="flex justify-between items-start mb-4">
                <h2 className="text-3xl font-bold text-gray-900">NIHAYA</h2>
                <a 
                  href="https://nihaya.eu" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="inline-flex items-center gap-2 text-primary hover:text-primary-dark transition-colors duration-300"
                >
                  <span>Bezoek Website</span>
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </a>
              </div>

              <p className="text-gray-600 mb-6">
                Een luxe fashion merk waarvoor ik het design en de huidige website heb ontwikkeld. In de toekomst zal dit uitgebreid worden naar een volledige e-commerce oplossing met Shopify integratie.
              </p>

              {/* Image Gallery */}
              <div className="mb-8">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Website Screenshots</h3>
                <div className="grid grid-cols-3 gap-4">
                  {nihayaImages.map((image, index) => (
                    <div 
                      key={index}
                      className="relative group cursor-pointer overflow-hidden rounded-lg"
                      onClick={() => setSelectedImage(image.src)}
                    >
                      <img 
                        src={image.src} 
                        alt={image.alt}
                        className="w-full h-auto transform transition-transform duration-300 group-hover:scale-105"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity duration-300 flex items-center justify-center">
                        <span className="text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          Bekijk
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Technische Architectuur</h3>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                  <li>React voor dynamische front-end ontwikkeling</li>
                  <li>TailwindCSS voor geoptimaliseerde styling</li>
                  <li>Figma voor UI/UX prototyping en design systeem</li>
                  <li>Vercel voor snelle, betrouwbare hosting en naadloze implementatie van nieuwe functionaliteiten.</li>
                </ul>
              </div>
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Huidige Functionaliteiten</h3>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                  <li>Responsive design voor weergave op alle apparaten</li>
                  <li>Moderne animaties en soepele pagina-overgangen</li>
                </ul>
              </div>
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">E-commerce Roadmap</h3>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                  <li>Volledige Shopify integratie voor e-commerce functionaliteit</li>
                  <li>Geavanceerd product filtering en zoeksysteem</li>
                  <li>Geoptimaliseerde checkout experience</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Technical Skills */}
      <section className="py-16 bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-12">Technische Vaardigheden</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Frontend</h3>
              <ul className="space-y-4 text-gray-600">
                <li className="flex items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-11.5 -10.23174 23 20.46348" className="w-6 h-6">
                    <circle cx="0" cy="0" r="2.05" fill="#61dafb"/>
                    <g stroke="#61dafb" strokeWidth="1" fill="none">
                      <ellipse rx="11" ry="4.2"/>
                      <ellipse rx="11" ry="4.2" transform="rotate(60)"/>
                      <ellipse rx="11" ry="4.2" transform="rotate(120)"/>
                    </g>
                  </svg>
                  React
                </li>
                <li className="flex items-center gap-2">
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M11.572 0c-.176 0-.31.001-.358.007a19.76 19.76 0 0 1-.364.033C7.443.346 4.25 2.185 2.228 5.012a11.875 11.875 0 0 0-2.119 5.243c-.096.659-.108.854-.108 1.747s.012 1.089.108 1.748c.652 4.506 3.86 8.292 8.209 9.695.779.25 1.6.422 2.534.525.363.04 1.935.04 2.299 0 1.611-.178 2.977-.577 4.323-1.264.207-.106.247-.134.219-.158-.02-.013-.9-1.193-1.955-2.62l-1.919-2.592-2.404-3.558a338.739 338.739 0 0 0-2.422-3.556c-.009-.002-.018 1.579-.023 3.51-.007 3.38-.01 3.515-.052 3.595a.426.426 0 0 1-.206.214c-.075.037-.14.044-.495.044H7.81l-.108-.068a.438.438 0 0 1-.157-.171l-.05-.106.006-4.703.007-4.705.072-.092a.645.645 0 0 1 .174-.143c.096-.047.134-.051.54-.051.478 0 .558.018.682.154.035.038 1.337 1.999 2.895 4.361a10760.433 10760.433 0 0 0 4.735 7.17l1.9 2.879.096-.063a12.317 12.317 0 0 0 2.466-2.163 11.944 11.944 0 0 0 2.824-6.134c.096-.66.108-.854.108-1.748 0-.893-.012-1.088-.108-1.747-.652-4.506-3.859-8.292-8.208-9.695a12.597 12.597 0 0 0-2.499-.523A33.119 33.119 0 0 0 11.573 0zm4.069 7.217c.347 0 .408.005.486.047a.473.473 0 0 1 .237.277c.018.06.023 1.365.018 4.304l-.006 4.218-.744-1.14-.746-1.14v-3.066c0-1.982.01-3.097.023-3.15a.478.478 0 0 1 .233-.296c.096-.05.13-.054.5-.054z"/>
                  </svg>
                  Next.js
                </li>
                <li className="flex items-center gap-2">
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12.001,4.8c-3.2,0-5.2,1.6-6,4.8c1.2-1.6,2.6-2.2,4.2-1.8c0.913,0.228,1.565,0.89,2.288,1.624 C13.666,10.618,15.027,12,18.001,12c3.2,0,5.2-1.6,6-4.8c-1.2,1.6-2.6,2.2-4.2,1.8c-0.913-0.228-1.565-0.89-2.288-1.624 C16.337,6.182,14.976,4.8,12.001,4.8z M6.001,12c-3.2,0-5.2,1.6-6,4.8c1.2-1.6,2.6-2.2,4.2-1.8c0.913,0.228,1.565,0.89,2.288,1.624 c1.177,1.194,2.538,2.576,5.512,2.576c3.2,0,5.2-1.6,6-4.8c-1.2,1.6-2.6,2.2-4.2,1.8c-0.913-0.228-1.565-0.89-2.288-1.624 C10.337,13.382,8.976,12,6.001,12z"/>
                  </svg>
                  TailwindCSS
                </li>
              </ul>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Backend</h3>
              <ul className="space-y-4 text-gray-600">
                <li className="flex items-center gap-2">
                  <svg className="w-6 h-6" viewBox="0 0 448 512" fill="currentColor">
                    <path d="M224 508c-6.7 0-13.5-1.8-19.4-5.2l-61.7-36.5c-9.2-5.2-4.7-7-1.7-8 12.3-4.3 14.8-5.2 27.9-12.7 1.4-.8 3.2-.5 4.6.4l47.4 28.1c1.7 1 4.1 1 5.7 0l184.7-106.6c1.7-1 2.8-3 2.8-5V149.3c0-2.1-1.1-4-2.9-5.1L226.8 37.7c-1.7-1-4-1-5.7 0L36.6 144.3c-1.8 1-2.9 3-2.9 5.1v213.1c0 2 1.1 4 2.9 4.9l50.6 29.2c27.5 13.7 44.3-2.4 44.3-18.7V167.5c0-3 2.4-5.3 5.4-5.3h23.4c2.9 0 5.4 2.3 5.4 5.3V378c0 36.6-20 57.6-54.7 57.6-10.7 0-19.1 0-42.5-11.6l-48.4-27.9C8.1 389.2.7 376.3.7 362.4V149.3c0-13.8 7.4-26.8 19.4-33.7L204.6 9c11.7-6.6 27.2-6.6 38.8 0l184.7 106.7c12 6.9 19.4 19.8 19.4 33.7v213.1c0 13.8-7.4 26.7-19.4 33.7L243.4 502.8c-5.9 3.4-12.6 5.2-19.4 5.2zm149.1-210.1c0-39.9-27-50.5-83.7-58-57.4-7.6-63.2-11.5-63.2-24.9 0-11.1 4.9-25.9 47.4-25.9 37.9 0 51.9 8.2 57.7 33.8.5 2.4 2.7 4.2 5.2 4.2h24c1.5 0 2.9-.6 3.9-1.7s1.5-2.6 1.4-4.1c-3.7-44.1-33-64.6-92.2-64.6-52.7 0-84.1 22.2-84.1 59.5 0 40.4 31.3 51.6 81.8 56.6 60.5 5.9 65.2 14.8 65.2 26.7 0 20.6-16.6 29.4-55.5 29.4-48.9 0-59.6-12.3-63.2-36.6-.4-2.6-2.6-4.5-5.3-4.5h-23.9c-3 0-5.3 2.4-5.3 5.3 0 31.1 16.9 68.2 97.8 68.2 58.4-.1 92-23.2 92-63.4z"/>
                  </svg>
                  Node.js
                </li>
                <li className="flex items-center gap-2">
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M24 18.588a1.529 1.529 0 0 1-1.895-.72l-3.45-4.771-.5-.667-4.003 5.444a1.466 1.466 0 0 1-1.802.708l5.158-6.92-4.798-6.251a1.595 1.595 0 0 1 1.9.666l3.576 4.83 3.596-4.81a1.435 1.435 0 0 1 1.788-.668L21.708 7.9l-2.522 3.283a.666.666 0 0 0 0 .994l4.804 6.412zM.002 11.576l.42-2.075c1.154-4.103 5.858-5.81 9.094-3.27 1.895 1.489 2.368 3.597 2.275 5.973H1.116C.943 16.447 4.005 19.009 7.92 17.7a4.078 4.078 0 0 0 2.582-2.876c.207-.666.548-.78 1.174-.588a5.417 5.417 0 0 1-2.589 3.957 6.272 6.272 0 0 1-7.306-.933 6.575 6.575 0 0 1-1.64-3.858c0-.235-.08-.455-.134-.666A88.33 88.33 0 0 1 0 11.577zm1.127-.286h9.654c-.06-3.076-2.001-5.258-4.59-5.278-2.882-.04-4.944 2.094-5.071 5.264z"/>
                  </svg>
                  Express
                </li>
                <li className="flex items-center gap-2">
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M3.89 15.672L6.255.461A.542.542 0 017.27.288l2.543 4.771zm16.794 3.692l-2.25-14a.54.54 0 00-.919-.295L3.316 19.365l7.856 4.427a1.621 1.621 0 001.588 0zM14.3 7.147l-1.82-3.482a.542.542 0 00-.96 0L3.53 17.984z"/>
                  </svg>
                  Firebase
                </li>
                <li className="flex items-center gap-2">
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M17.193 9.555c-1.264-5.58-4.252-7.414-4.573-8.115-.28-.394-.53-.954-.735-1.44-.036.495-.055.685-.523 1.184-.723.566-4.438 3.682-4.74 10.02-.282 5.912 4.27 9.435 4.888 9.884l.07.05A73.49 73.49 0 0111.91 24h.481c.114-1.032.284-2.056.51-3.07.417-.296.604-.463.85-.693a11.342 11.342 0 003.639-8.464c.01-.814-.103-1.662-.197-2.218zm-5.336 8.195s0-8.291.275-8.29c.213 0 .49 10.695.49 10.695-.381-.045-.765-1.76-.765-2.405z"/>
                  </svg>
                  MongoDB
                </li>
              </ul>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Tools</h3>
              <ul className="space-y-4 text-gray-600">
                <li className="flex items-center gap-2">
                  <img 
                    src="https://www.svgrepo.com/show/303503/shopify-logo.svg"
                    alt="Shopify Logo"
                    className="w-6 h-6"
                  />
                  Shopify
                </li>
                <li className="flex items-center gap-2">
                  <img 
                    src="https://www.svgrepo.com/show/452202/figma.svg"
                    alt="Figma Logo"
                    className="w-6 h-6"
                  />
                  Figma
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Portfolio Summary */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">Portfolio Overzicht</h2>
          <div className="prose prose-lg max-w-none text-gray-600">
            <p className="mb-6">
              Dit portfolio toont een diverse selectie van projecten die mijn expertise in verschillende gebieden demonstreren:
            </p>
            <ul className="space-y-4 mb-6">
              <li>
                <strong>App Ontwikkeling (WADM Mindfuel)</strong>: Een succesvolle stage-opdracht die mijn vermogen toont om mobiele applicaties te ontwikkelen met moderne technologieën.
              </li>
              <li>
                <strong>AI & E-commerce (Prodify)</strong>: Een innovatieve oplossing die AI-technologie combineert met e-commerce, specifiek gericht op de TikTok Shop markt.
              </li>
              <li>
                <strong>Web Development (NIHAYA)</strong>: Een showcase van mijn web development vaardigheden, met focus op modern design en gebruikerservaring, met plannen voor toekomstige e-commerce integratie.
              </li>
            </ul>
            <p>
              Elk project weerspiegelt mijn toewijding aan kwaliteit, innovatie en klantgerichte oplossingen. Van concept tot implementatie streef ik naar het leveren van hoogwaardige digitale producten die echte waarde toevoegen voor gebruikers en bedrijven.
            </p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-gradient-start to-gradient-end text-white py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div className="space-y-2">
              <div>
                <h3 className="font-bold mb-1">Email</h3>
                <a 
                  href="mailto:info@joshua-digital.com" 
                  className="text-white/80 hover:text-white transition-colors duration-300"
                >
                  info@joshua-digital.com
                </a>
              </div>
              <div>
                <h3 className="font-bold mb-1">LinkedIn</h3>
                <a 
                  href="https://www.linkedin.com/in/joshua-silva-maniche/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white/80 hover:text-white transition-colors duration-300"
                >
                  Joshua Silva Maniche
                </a>
              </div>
              <div>
                <h3 className="font-bold mb-1">GitHub</h3>
                <a 
                  href="https://github.com/JoshuaLevi" 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white/80 hover:text-white transition-colors duration-300"
                >
                  JoshuaLevi
                </a>
              </div>
              <div>
                <h3 className="font-bold mb-1">Website</h3>
                <a 
                  href="https://joshua-digital.com" 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white/80 hover:text-white transition-colors duration-300"
                >
                  joshua-digital.com
                </a>
              </div>
            </div>
            <div className="flex justify-end">
              <img 
                src={logo} 
                alt="Joshua Digital Logo" 
                className="h-24 w-24 object-contain drop-shadow-2xl"
              />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Portfolio; 