export const translations = {
  nl: {
    nav: {
      process: 'Proces',
      faq: 'FAQ',
      contact: 'Contact'
    },
    hero: {
      title: 'Van idee naar werkende software in 45 dagen',
      subtitle: 'Geen maandenlange trajecten of complexe processen. Wij bouwen jouw app, dashboard of AI-oplossing in 45 dagen, zodat je direct kunt starten.',
      bulletPoints: [
        'Direct resultaat: Binnen 45 dagen live en klaar voor gebruik',
        'Persoonlijke aanpak: Volledige focus op jouw succes',
        'Bewezen methode: Van concept naar werkend product'
      ],
      cta: 'Plan een vrijblijvend strategiegesprek',
      quickResponse: 'Direct antwoord binnen kantooruren'
    },
    usp: {
      title: 'Waarom wachten, als je direct resultaat kunt boeken?',
      subtitle: 'Ons 45-dagen model geeft je alles wat je nodig hebt om snel live te gaan en impact te maken. Hier is waarom klanten ons kiezen:',
      items: [
        {
          title: 'Tijd besparen',
          description: 'Wij doen het werk, jij blijft gefocust op je bedrijf',
          icon: '<svg className="w-10 h-10 text-primary" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5"><path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>'
        },
        {
          title: 'Directe validatie',
          description: 'Start met valideren binnen 45 dagen',
          icon: '<svg className="w-10 h-10 text-primary" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5"><path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" /></svg>'
        },
        {
          title: 'Frustraties vermijden',
          description: 'Geen eindeloze iteraties of technische uitdagingen',
          icon: '<svg className="w-10 h-10 text-primary" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5"><path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" /></svg>'
        },
        {
          title: 'Efficiëntie door AI',
          description: 'Automatiseer repetitieve taken met slimme oplossingen',
          icon: '<svg className="w-10 h-10 text-primary" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5"><path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" /></svg>'
        },
        {
          title: 'Klaar voor groei',
          description: 'Alles wordt gebouwd om makkelijk uit te breiden',
          icon: '<svg className="w-10 h-10 text-primary" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5"><path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" /></svg>'
        }
      ],
      examples: {
        title: 'Wat wij bouwen:',
        subtitle: 'Onze focus ligt op het oplossen van jouw probleem. Hier zijn enkele voorbeelden van oplossingen die we binnen 45 dagen hebben gebouwd:',
        items: [
          'Een AI-chatbot: Bespaar 80% van je klantenservice-tijd door standaardvragen te automatiseren.',
          'Een intern dashboard: Real-time inzicht in je belangrijkste bedrijfsdata, alles in één overzicht.',
          'Een veiligheidsapp: Meld incidenten direct en automatiseer het proces voor managers.',
          'Een e-commerce platform: Snel online en direct starten met verkopen.'
        ]
      },
      cta: 'Wil je een specifieke uitdaging oplossen? Plan je gratis strategiegesprek.'
    },
    process: {
      title: 'Van idee naar winst in 45 dagen',
      subtitle: 'Wij maken het simpel. Hier is hoe we jouw idee werkelijkheid maken:',
      steps: [
        {
          step: '01',
          title: 'Kick-Off',
          description: 'Samen bespreken we je grootste uitdagingen en hoe we deze direct kunnen oplossen'
        },
        {
          step: '02',
          title: 'Ontwikkeling',
          description: 'Dagelijkse updates, snelle iteraties en een werkend product binnen 45 dagen'
        },
        {
          step: '03',
          title: 'Live & Feedback',
          description: 'Test je oplossing in de markt en ontdek direct wat werkt'
        },
        {
          step: '04',
          title: 'Plan voor groei',
          description: 'Inclusief een strategie om je oplossing verder te verbeteren en op te schalen'
        }
      ],
      cta: 'Klaar om de eerste stap te zetten? Plan je gesprek nu.'
    },
    caseStudy: {
      title: 'Hoe we klanten helpen',
      cases: [
        {
          title: 'Van idee naar veiligheidsplatform: FerrySafe\'s bedrijfsveiligheid app',
          description: 'Leen wilde met zijn bedrijf FerrySafe andere bedrijven helpen om de veiligheid op de werkvloer te verbeteren. Wij ontwikkelden een gebruiksvriendelijke app waarmee werknemers direct onveilige situaties kunnen melden. Managers krijgen via een dashboard real-time inzicht in alle meldingen en kunnen data-gedreven beslissingen nemen over veiligheidsverbeteringen.',
          results: [
            'Werknemers kunnen in 30 seconden een melding maken',
            'Managers hebben direct inzicht in veiligheidstrends',
            'Automatische rapportages besparen uren administratief werk',
            'Data-gedreven aanpak leidt tot betere veiligheidscultuur'
          ]
        }
      ],
      cta: 'Wil jij dit ook? Plan jouw strategiegesprek.'
    },
    faq: {
      title: 'Veelgestelde Vragen',
      questions: [
        {
          question: 'Wat kost het?',
          answer: 'We geloven in transparantie en waarde. Tijdens het strategiegesprek bespreken we jouw specifieke wensen en krijg je een voorstel op maat dat past bij jouw doelstellingen.'
        },
        {
          question: 'Wat als mijn project complexer is?',
          answer: 'We focussen op het deel dat de meeste impact heeft op jouw resultaat. Dit deel krijg je binnen 45 dagen, daarna bouwen we verder.'
        },
        {
          question: 'Hoe garanderen jullie resultaat?',
          answer: 'Door focus. We werken aan één project tegelijk en leveren dagelijkse updates zodat alles in lijn blijft met jouw doelstellingen.'
        },
        {
          question: 'Wat gebeurt er na 45 dagen?',
          answer: 'Je krijgt een werkend product én een groeiplan om resultaten verder te vergroten.'
        }
      ]
    },
    contact: {
      title: 'Stop met wachten, start met bouwen',
      subtitle: 'Ben je klaar om je idee te lanceren? Of het nu gaat om een AI-oplossing, dashboard, app of iets anders – wij zorgen dat het binnen 45 dagen live is.',
      form: {
        name: 'Naam',
        email: 'E-mail',
        projectDescription: 'Wat wil je bereiken?',
        projectPlaceholder: 'Beschrijf je grootste uitdaging en wanneer je resultaat wilt zien',
        sendMessage: 'Plan Strategiegesprek',
        sending: 'Aanvraag versturen...',
        responseTime: 'Direct antwoord binnen kantooruren'
      }
    },
    footer: {
      rights: 'Alle rechten voorbehouden'
    }
  },
  en: {
    nav: {
      process: 'Process',
      faq: 'FAQ',
      contact: 'Contact'
    },
    hero: {
      title: 'From idea to working software in 45 days',
      subtitle: 'No lengthy projects or complex processes. We build your app, dashboard, or AI solution in 45 days, so you can start selling immediately.',
      bulletPoints: [
        'Immediate results: Live and ready to use within 45 days',
        'Personal approach: Complete focus on your success',
        'Proven method: From concept to working product'
      ],
      cta: 'Schedule a free strategy call',
      quickResponse: 'Immediate response during business hours'
    },
    usp: {
      title: 'Why wait when you can get results now?',
      subtitle: 'Our 45-day model gives you everything you need to go live quickly and make an impact. Here\'s why clients choose us:',
      items: [
        {
          title: 'Save time',
          description: 'We do the work, you stay focused on your business',
          icon: '<svg className="w-10 h-10 text-primary" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5"><path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>'
        },
        {
          title: 'Immediate revenue',
          description: 'Start selling or validating within 45 days',
          icon: '<svg className="w-10 h-10 text-primary" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5"><path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" /></svg>'
        },
        {
          title: 'Avoid frustration',
          description: 'No endless iterations or technical challenges',
          icon: '<svg className="w-10 h-10 text-primary" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5"><path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" /></svg>'
        },
        {
          title: 'AI efficiency',
          description: 'Automate repetitive tasks with smart solutions',
          icon: '<svg className="w-10 h-10 text-primary" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5"><path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" /></svg>'
        },
        {
          title: 'Ready for growth',
          description: 'Everything built for easy expansion',
          icon: '<svg className="w-10 h-10 text-primary" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5"><path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" /></svg>'
        }
      ],
      examples: {
        title: 'What we build:',
        subtitle: 'Our focus is on solving your problem. Here are some examples of solutions we\'ve built within 45 days:',
        items: [
          'An AI chatbot: Save 80% of customer service time by automating standard questions.',
          'An internal dashboard: Real-time insight into your key business data, all in one view.',
          'A safety app: Report incidents instantly and automate the process for managers.',
          'An e-commerce platform: Get online and start selling immediately.'
        ]
      },
      cta: 'Want to solve a specific challenge? Schedule your free strategy call.'
    },
    process: {
      title: 'From idea to profit in 45 days',
      subtitle: 'We make it simple. Here\'s how we turn your idea into reality:',
      steps: [
        {
          step: '01',
          title: 'Strategic Kick-Off',
          description: 'Together we discuss your biggest challenges and how to solve them immediately'
        },
        {
          step: '02',
          title: 'Rapid Development',
          description: 'Daily updates, quick iterations, and a working product within 45 days'
        },
        {
          step: '03',
          title: 'Live & Feedback',
          description: 'Test your solution in the market and discover what works immediately'
        },
        {
          step: '04',
          title: 'Growth Plan',
          description: 'Including a strategy to further improve and scale your solution'
        }
      ],
      cta: 'Ready to take the first step? Schedule your call now.'
    },
    caseStudy: {
      title: 'How we help clients',
      cases: [
        {
          title: 'From idea to safety platform: FerrySafe\'s workplace safety app',
          description: 'Leen wanted to help companies improve workplace safety with his company FerrySafe. We developed a user-friendly app that allows employees to report unsafe situations instantly. Managers get real-time insights into all reports through a dashboard and can make data-driven decisions about safety improvements.',
          results: [
            'Employees can create a report in 30 seconds',
            'Managers have instant insight into safety trends',
            'Automated reports save hours of administrative work',
            'Data-driven approach leads to better safety culture'
          ]
        }
      ],
      cta: 'Want this too? Schedule your strategy call.'
    },
    faq: {
      title: 'Frequently Asked Questions',
      questions: [
        {
          question: 'What does it cost?',
          answer: 'We believe in transparency and value. During the strategy call, we\'ll discuss your specific needs and provide a tailored proposal that aligns with your objectives.'
        },
        {
          question: 'What if my project is more complex?',
          answer: 'We focus on the part that has the most impact on your results. You\'ll get this part within 45 days, then we build further.'
        },
        {
          question: 'How do you guarantee results?',
          answer: 'Through focus. We work on one project at a time and provide daily updates to ensure everything stays aligned with your objectives.'
        },
        {
          question: 'What happens after 45 days?',
          answer: 'You get a working product AND a growth plan to further increase results.'
        }
      ]
    },
    contact: {
      title: 'Stop waiting, start building',
      subtitle: 'Ready to launch your idea? Whether it\'s an AI solution, dashboard, app, or something else – we\'ll ensure it\'s live within 45 days.',
      form: {
        name: 'Name',
        email: 'Email',
        projectDescription: 'What do you want to achieve?',
        projectPlaceholder: 'Describe your biggest challenge and when you need results',
        sendMessage: 'Schedule Strategy Call',
        sending: 'Sending request...',
        responseTime: 'Immediate response during business hours'
      }
    },
    footer: {
      rights: 'All rights reserved'
    }
  }
}; 