import React, { createContext, useState, useContext, useEffect } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('nl'); // Default to Dutch

  useEffect(() => {
    // Get browser language
    const browserLang = navigator.language.split('-')[0];
    // Set to Dutch if browser language is Dutch, otherwise keep default
    if (browserLang === 'nl') {
      setLanguage('nl');
    }
  }, []);

  const toggleLanguage = () => {
    setLanguage(prevLang => prevLang === 'nl' ? 'en' : 'nl');
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}; 